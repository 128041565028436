<template>
  <div class="home">
    <div class="first">
      <div class="first-time" v-if="successpay == false">
        支付剩余时间
        <div
          style="
            margin: 0 10px;
            letter-spacing: 1px;
            display: flex;
            align-items: center;
          "
        >
          {{ countM }}
          <div v-if="countM != '' && countS != ''" style="margin: 0 5px">:</div>
          {{ countS }}
        </div>

        <!-- <div v-else>停止时间</div> -->
      </div>

      <div class="first-time" v-else>
        {{ successpayIndex == 0 ? "已支付" : "支付失败" }}
      </div>

      <div class="first-price">
        <span style="font-size: 18px">￥</span>
        {{ totalFee }}
      </div>

      <div class="first-title">深圳市宫匠科技有限公司</div>
    </div>

    <div class="second">
      <div class="second-title">支付方式</div>
      <div class="second-lint"></div>
      <div class="second-display">
        <div class="left">
          <div class="left-img">
            <img src="../assets/wx.png" alt="" />
          </div>
          <div class="left-title">微信支付</div>
        </div>
        <div class="right">
          <div
            v-if="selectedShow == false"
            class="right-checkout"
            @click="selectedShow = !selectedShow"
          ></div>
          <img
            v-else
            src="../assets/check_selected.png"
            @click="selectedShow = !selectedShow"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
import { postcollaborate_pay, getopenid } from "../api/logo"; //获取登录信息状态
export default {
  data() {
    return {
      jm_str: "",
      token: "",
      userhome_id: "",
      paycode: "",
      totalFee: "",
      orderNumber: "",
      differentiate: "",
      //-----------
      appid: "wxf6a1114cc60d1c37",
      appsecret: "911f851b4f54d266e20ad92bb6f8c287",
      openid: "",
      //---------------
      countM: "", //倒计时
      countS: "",
      seconds: 3600, // 10天的秒数
      selectedShow: true,
      successpay: false,
    };
  },
  created() {
    if (this.$route.query.token) {
      localStorage.setItem("token", this.$route.query.token);
    }
    clearInterval(this.timers);

    let code = this.getUrlKey("code");
    let state = this.getUrlKey("state");

    let obj = JSON.parse(this.getUrlKey("obj"));
    if (code) {
      // alert(code)
      let params = {
        code: code,
        state: state,
      };
      // alert(JSON.stringify(params))
      getopenid(params).then((res) => {
      // alert('哈哈哈啊哈啊哈')

      // alert(JSON.stringify(res))

        if (res.code == 200) {
          this.token = obj.token;
          this.userhome_id = state;
          this.orderNumber = obj.orderNumber;
          this.totalFee = obj.totalFee;

          this.cashpledgeMet();
        }
      });
    } else {
      this.orderNumber = this.$route.query.orderNumber;
      this.token = this.$route.query.token;
      this.userhome_id = this.$route.query.userhome_id;
      this.totalFee = this.$route.query.totalFee;

      this.$nextTick(() => {
        this.getCodeApi();
      });
    }
  },
  mounted() {
    this.Time(); //调用定时器
  },
  methods: {
    // 获取code
    // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60));
      d = d < 10 ? "0" + d : d;
      let h = parseInt((this.seconds / (60 * 60)) % 24);
      h = h < 10 ? "0" + h : h;
      let m = parseInt((this.seconds / 60) % 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(this.seconds % 60);
      s = s < 10 ? "0" + s : s;
      this.countM = m;
      this.countS = s;
    },
    //定时器没过1秒参数减1
    Time() {
      setInterval(() => {
        this.seconds -= 1;
        this.countDown();
      }, 1000);
    },

    //-------------------------------测试环境模块
    // 押金支付
    cashpledgeMet() {
      let params = {
        //----------------请求参数
        orderId: this.orderNumber.toString(),
        payType: 0,
        platform: "Yinsheng",
        way: "scan",
        channel: "wechat",
      };

      // alert(JSON.stringify(params))

      // let str = localStorage.getItem('token')

      // alert(str)

      postcollaborate_pay(params).then(
        (res) => {
          // alert(JSON.stringify(res));

          if (res.code == 200) {
            let obj = JSON.parse(res.result.payData);

            this.onBridgeReady(obj);
          } else {
            this.getCodeApi();
          }
        },
        (err) => {

          this.getCodeApi();
        }
      );
    },

    //-------------------功能性开始
    getCodeApi() {
      //获取code
      let obj = {
        orderNumber: this.orderNumber,
        totalFee: this.totalFee,
        token: this.token,
      };

      let str = JSON.stringify(obj);

      let urls = `https://pay.gjlike.com?obj=${str}`;

      let url =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        this.appid +
        "&redirect_uri=" +
        urls +
        "&response_type=code&scope=snsapi_base&state=" +
        this.userhome_id +
        "#wechat_redirect";

      window.location.href = url;
    },
    getUrlKey: function (name) {
      //获取url 参数
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },

    //---------------------------------
    // 调取支付
    onBridgeReady(item) {
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: item.appId, //公众号名称，由商户传入
          timeStamp: item.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: item.nonceStr, //随机串
          package: item.package,
          signType: item.signType, //微信签名方式：
          paySign: item.paySign, //微信签名
        },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            this.successpayIndex = 0;
            this.successpay = true;
          } else {
            this.successpay = true;
            this.successpayIndex = 1;
          } // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。
        }
      );
    },
  },
};
</script>
  
<style lang="less" scoped>
.home {
  width: 100vw;
  height: 100vh;
  background: #f6f6f6;
  .first {
    padding-top: 60px;
    .first-time {
      height: 50px;
      width: 80vw;
      border-radius: 20px;
      margin: 0 auto;
      background: #fff;
      font-size: 16px;
      color: #ff6821;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .first-price {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      font-weight: bold;
      span {
      }
    }

    .first-title {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      margin-top: 40px;
    }
  }

  .second {
    // padding: 20px;
    // box-sizing: border-box;
    background: #fff;
    border-radius: 5px;
    width: 90vw;
    margin: 0 auto;
    margin-top: 20px;

    .second-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      box-sizing: border-box;
    }

    .second-lint {
      width: 100%;
      height: 1px;
      background: #f6f6f6;
      margin: 10px 0;
    }

    .second-display {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding: 0 20px;
      box-sizing: border-box;

      .left {
        display: flex;

        .left-img {
          width: 24px;
          height: 24px;
          margin-right: 5px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .left-title {
          font-size: 16px;
          font-weight: 500;
        }
      }

      .right {
        img {
          width: 18px;
          height: 18px;
          border-radius: 50%;
        }

        .right-checkout {
          width: 18px;
          height: 18px;
          border: 1px solid #ccc;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>