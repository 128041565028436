// import axios from 'axios'
// import qs from 'qs'


// const instance = axios.create({
//     baseURL: 'https://gongqiangafter.gjlike.com',
//     timeout: 50000
// })


// instance.defaults.transformRequest = data => qs.stringify(data);
// instance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
// instance.interceptors.request.use(
//     config => {
//         if (localStorage.getItem('token')) {
//             config.headers['X-Access-Token'] = localStorage.getItem('token')
//         }


//         config.headers["Content-Type"] = "application/x-www-form-urlencoded";
//         return config;
//     },
//     error => {
//         // Do something with request error
//         console.log(error); // for debug
//         Promise.reject(error);
//     }
// );

// // axios response封装的数据在data里面
// instance.interceptors.response.use(
//     response => {
//         return response.data;
//     },
//     error => {
//         let { response } = error;

//         return Promise.reject(response.data)

//         if (response) { // 如果response存在，服务器最起码返回结果
//             switch (response.status) { //这一块根据服务器来进行判断编写的
//                 case 401:  // 权限  当前请求需要用户登录（一般叫做未登录）
//                     break;
//                 case 403:  // 服务器拒绝执行， （一般是token过期）
//                     // localStorage.getItem
//                     break;
//                 case 404:   // 找不到页面存在
//                     break;
//             }
//         } else { // 服务器连结果都没有返回，这里只有服务器崩了或者断网问题
//             if (!window.navigator.onLine) {
//                 // 断网处理： 可以跳转断网页面
//                 return;
//             }
//             return Promise.reject(error)
//         }
//     }
// )

// function setHeader(config) {
//     // setHeader
//     // 

//     return;
// }

// export default instance;



import axios from 'axios'
import Qs from 'qs';
// 环境的切换:开发，测试，生产
axios.defaults.baseURL = 'https://gongqiangafter.gjlike.com'
axios.defaults.timeout = 100000
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data'
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

// // 请求拦截器
axios.interceptors.request.use(config => {
    // 在发送请求之前做些什么 验证token之类的
    config.headers['X-Access-Token'] = localStorage.getItem('token')

    return config;
}, error => {
    // 对请求错误做些什么
    Message.error({ message: '请求超时!' })
    return Promise.error(error);
})

// 响应拦截
axios.interceptors.response.use(response => {
    // 对响应数据做点什么
    return response;
}, error => {
    // 对响应错误做点什么
    return Promise.reject(error);
});

// 封装get方法和post方法

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

/**
 * post方法，参数序列化
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function qspost(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, Qs.stringify(params))
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}


/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
    return new Promise((resolve, reject) => {
        axios.put(url, params)
            .then(res => {
                resolve(res.data);

            })
            .catch(err => {
                reject(err.data)
            })
    });
}


/**
 * delete
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function deletefn(url, params) {
    return new Promise((resolve, reject) => {
        axios.delete(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
                // Loading.service(true).close();
            })
    });
}
